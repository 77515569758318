import React from 'react';
import cn from 'classnames';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';

import {startLoginAction} from 'redux/auth/authAsyncActions';
import {appStoreUrl, googlePlayUrl, intIosAccess, vimeoId} from 'utils/constants/constants';

import VimeoPlayer from 'components/vimeo-player';

import story from 'assets/image/home-page/story.svg';
import whyGritx from 'assets/image/home-page/why-gritX.svg';
import AppStore from 'assets/image/social-mobile/AppStore.svg';
import GoogleApp from 'assets/image/connect-line/downloadViaGooglePlay.svg';
import Apple from 'assets/image/social-mobile/apple.svg';
import Google from 'assets/image/social-mobile/android.svg';
import QRApple from 'assets/image/social-mobile/qr-apple.svg';
import QRGoogle from 'assets/image/social-mobile/qr-google.svg';

import './styles.scss';

const RIBoldText = (t: string) => <b>{t}</b>;

const sections = [
  {
    img: story,
    keyTitle: 'gritx.home.main.section1.title',
    title: 'How we can help',
    keyDescription: 'gritx.home.main.section1.description',
    description: 'GritX teaches methods to help you manage your emotions, cope with trauma, resolve interpersonal conflicts, and grow your self-reflection'
      + ' through interactive prompts. You can save your favorite self-care tools for quick access when you need it, anytime, anywhere, and without cost. '
  },
  {
    img: whyGritx,
    bgcolor: '',
    keyTitle: 'gritx.home.main.section2.title',
    title: 'Why we do this',
    keyDescription: 'gritx.home.main.section2.description',
    description: 'We’re on a mission to radically scale access to quality mental health resources so you and others have the tools you need to live a'
      + ' fulfilling life. We are GritX and you are not alone in this. '
  }
];

const AppsSection = () => {
  return <div className="apps">
    <div className="apps-content">
      <p className="apps-content-text">
        <FormattedMessage
          id={'gritx.home.apps.title'}
          defaultMessage={'Apps'}
        />
      </p>
      <div className="apps__store">
        <div className="apps__qr">
          <div className="apps__qr-logo">
            <img
              className="apps__qr-image"
              src={Apple}
              alt=""
              role="presentation"
            />
          </div>
          <div className="apps__qr-code">
            <img
              className="apps__qr-code-image"
              src={QRApple}
              alt=""
              role="presentation"
            />
          </div>
        </div>
        <div className="apps__button">
          <a
            target="_blank"
            rel="noreferrer"
            href={appStoreUrl}
          >
            <img
              className="apps__button-image"
              src={AppStore}
              alt=""
              role="presentation"
            />
            <span className="visually-hidden">
              <FormattedMessage
                id={'gritx.common.downloadApp.appStore'}
                defaultMessage={'Go to the download application in App Store'}
              />
            </span>
          </a>
        </div>
      </div>

      {/* <div className="apps__button--link">*/}
      {/*  <a*/}
      {/*    target="_blank"*/}
      {/*    rel="noreferrer"*/}
      {/*    href={intIosAccess}*/}
      {/*    className="apps__link"*/}
      {/*  >*/}
      {/*    <FormattedMessage*/}
      {/*      id={'gritx.home.apps.int-access'}*/}
      {/*      defaultMessage={'International iOS Access'}*/}
      {/*    />*/}
      {/*  </a>*/}
      {/* </div>*/}
      {/*<div className="apps__store">*/}
      {/*  <div className="apps__qr">*/}
      {/*    <div className="apps__qr-logo">*/}
      {/*      <img*/}
      {/*        className="apps__qr-image"*/}
      {/*        src={Google}*/}
      {/*        alt=""*/}
      {/*        role="presentation"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="apps__qr-code">*/}
      {/*      <img*/}
      {/*        className="apps__qr-code-image"*/}
      {/*        src={QRGoogle}*/}
      {/*        alt=""*/}
      {/*        role="presentation"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="apps__button">*/}
      {/*    <a*/}
      {/*      target="_blank"*/}
      {/*      rel="noreferrer"*/}
      {/*      href={googlePlayUrl}*/}
      {/*    >*/}
      {/*      <img*/}
      {/*        className="apps__button-image"*/}
      {/*        src={GoogleApp}*/}
      {/*        alt=""*/}
      {/*        role="presentation"*/}
      {/*      />*/}
      {/*      <span className="visually-hidden">*/}
      {/*        <FormattedMessage*/}
      {/*          id={'gritx.common.downloadApp.googlePlay'}*/}
      {/*          defaultMessage={'Go to the download application in Google Play'}*/}
      {/*        />*/}
      {/*      </span>*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  </div>;
};

export const MainArea: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  async function handleSignIn() {
    dispatch(startLoginAction());
  }

  return <section className="main-area">
    <div className="main-area__wrapper">
      <div className="main-area__about">
        <h2 className="main-area__header">
          <FormattedMessage
            id={'gritx.home.main-area.header'}
            defaultMessage={'Welcome to Your Best Self!'}
          />
        </h2>
        <div className="main-area__about-content">
          <div className="main-area__about-video">
            <VimeoPlayer
              videoId={vimeoId}
            />
          </div>
          <div className="main-area__about-description">
            <p className="main-area__about-text">
              <FormattedMessage
                id={'gritx.home.main.p1'}
                defaultMessage={'<b>What we can give YOU:</b> GritX is your judgment free guide to facing life challenges and personal growth.'
                + ' Learn scientifically proven, therapeutic exercises by our team of experts from the University of California, San Francisco.'
                + ' Something on your mind? Chat with GritX, our emotionally intelligent, conversational AI.'
                + ' Feeling adventurous? Embark on a GritXpedition and get in depth skill building resources.'
                + 'We put our tools in your hands completely free.'}
                values={{
                  b: RIBoldText
                }}
              />
            </p>
            <button
              className="main-area__about-button"
              onClick={handleSignIn}
            >
              <FormattedMessage
                id={'gritx.home.main.button'}
                defaultMessage={'Join Us Today'}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="main-area__apps">
        <AppsSection/>
      </div>
    </div>
    <div className="main-area__sections">
      {
        sections.map((item, i) => <div key={i} className="section">
          <div className={cn('section__image-wrapper', {
            [`section__image-wrapper--${item.bgcolor}`]: item.bgcolor
          })}>
            <img className={'section__image'} src={item.img} alt=""/>
          </div>
          <div className="section__about">
            {
              item.title.length !== 0
              && < h3 className='section__title'>
                <FormattedMessage
                  id={item.keyTitle}
                  defaultMessage={item.title}
                />
              </h3>
            }
            <p className="section__description">
              <FormattedMessage
                id={item.keyDescription}
                defaultMessage={item.description}
              />
            </p>
          </div>
        </div>)
      }
    </div>
  </section>;
};
