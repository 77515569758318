import {
  IArticle,
  IArticleCard,
  IContentActionHandler,
  IContentInitialState,
  IFilter,
  ILibraryFiltersResponse,
  ILibraryResponse,
  IShareTemplate
} from './interfaces';
import {
  ARTICLE_LOADED,
  BACKPACK_CONTENT_LOADED,
  CHECK_FAVORITE,
  CHECK_MATERIAL, CLEAR_SELECTED_FILTERS,
  CLOSE_ARTICLE,
  CONTENT_LOADED,
  ContentActionsType,
  FILTER_LOADED,
  MATERIALS_CONTENT_LOADED,
  SET_SELECTED_FILTERS,
  SET_SEND_MATERIALS_STATUS,
  SET_SHARE_TEMPLATES,
  UNCHECK_FAVORITE,
  UNCHECK_MATERIAL
} from './actions';

const ContentInitialState: IContentInitialState = {
  content: [],
  backpackContent: [],
  materialsContent: [],
  page: null,
  filters: null,
  article: null,
  selectedFilters: [],
  shareTemplates: [],
  sendMaterialsStatus: false
};

const setContent = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  const {
    data,
    page
  } = payload as ILibraryResponse;

  return {
    ...state,
    content: page.pageNumber === 0 ? data : [...state.content, ...data],
    page: page
  };
};

const setBackpackContent = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    backpackContent: payload as IArticleCard[]
  };
};

const setMaterialsContent = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    materialsContent: payload as IArticleCard[]
  };
};

const setFilters = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    filters: payload as ILibraryFiltersResponse
  };
};

const setArticle = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    article: {
      ...payload as IArticle
    }
  };
};
const closeArticle = ({
  state
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    article: null
  };
};

const checkFavorite = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    content: state.content.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          favorite: true
        };
      }

      return item;
    }),
    backpackContent: state.backpackContent.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          favorite: true
        };
      }

      return item;
    }),
    materialsContent: state.materialsContent.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          favorite: true
        };
      }

      return item;
    }),
    article: state.article?.id === payload ? {
      ...state.article,
      favorite: true
    } : state.article
  };
};
const uncheckFavorite = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    content: state.content.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          favorite: false
        };
      }

      return item;
    }),
    backpackContent: state.backpackContent.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          favorite: false
        };
      }

      return item;
    }),
    materialsContent: state.materialsContent.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          favorite: false
        };
      }

      return item;
    }),
    article: state.article?.id === payload ? {
      ...state.article,
      favorite: false
    } : state.article
  };
};

const checkMaterial = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    content: state.content.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          material: true
        };
      }

      return item;
    }),
    backpackContent: state.backpackContent.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          material: true
        };
      }

      return item;
    }),
    materialsContent: state.materialsContent.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          material: true
        };
      }

      return item;
    }),
    article: state.article?.id === payload ? {
      ...state.article,
      material: true
    } : state.article
  };
};
const uncheckMaterial = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  const uncheckIds = payload as number[];

  return {
    ...state,
    content: state.content.map((item) => {
      if (uncheckIds.includes(item.id)) {
        return {
          ...item,
          material: false
        };
      }

      return item;
    }),
    backpackContent: state.backpackContent.map((item) => {
      if (uncheckIds.includes(item.id)) {
        return {
          ...item,
          material: false
        };
      }

      return item;
    }),
    materialsContent: state.materialsContent.map((item) => {
      if (uncheckIds.includes(item.id)) {
        return {
          ...item,
          material: false
        };
      }

      return item;
    }),
    article: state.article && uncheckIds.includes(state.article.id) ? {
      ...state.article,
      material: false
    } : state.article
  };
};

const setSelectedFilters = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    selectedFilters: payload ? payload as IFilter[] : []
  };
};
const setShareTemplates = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    shareTemplates: payload as IShareTemplate[]
  };
};
const setSendMaterialsStatus = ({
  state,
  payload
}: IContentActionHandler): IContentInitialState => {
  return {
    ...state,
    sendMaterialsStatus: payload as boolean
  };
};

const statusReducerHandlers = new Map([
  [CONTENT_LOADED, setContent],
  [FILTER_LOADED, setFilters],
  [CLEAR_SELECTED_FILTERS, setSelectedFilters],
  [BACKPACK_CONTENT_LOADED, setBackpackContent],
  [MATERIALS_CONTENT_LOADED, setMaterialsContent],
  [ARTICLE_LOADED, setArticle],
  [CHECK_FAVORITE, checkFavorite],
  [UNCHECK_FAVORITE, uncheckFavorite],
  [CHECK_MATERIAL, checkMaterial],
  [UNCHECK_MATERIAL, uncheckMaterial],
  [ARTICLE_LOADED, setArticle],
  [CLOSE_ARTICLE, closeArticle],
  [SET_SELECTED_FILTERS, setSelectedFilters],
  [SET_SHARE_TEMPLATES, setShareTemplates],
  [SET_SEND_MATERIALS_STATUS, setSendMaterialsStatus]
]);

const contentReducer = (state = ContentInitialState, action: ContentActionsType): IContentInitialState => {
  const handler = statusReducerHandlers.get(action.type);

  return (handler && {
    ...handler(<IContentActionHandler>{
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default contentReducer;
