import React, {useEffect, useRef} from 'react';
import Vimeo from '@vimeo/player';

interface IVimeo {
  onLoad?: () => void
  onError?: () => void
  videoId: number
}

export const VimeoPlayer = ({
  onLoad = () => null,
  onError = () => null,
  videoId
}: IVimeo): JSX.Element => {
  const videoRef = useRef(null);

  useEffect(() => {
    const player = new Vimeo(videoRef.current as unknown as HTMLElement);

    player.ready()
      .then(() => {
        onLoad();
      })
      .catch(() => {
        onError();
      });
  }, [videoId]);

  return <div
    ref={videoRef}
    data-vimeo-id={videoId}
    data-vimeo-responsive="true"
    data-vimeo-byline="0"
    data-vimeo-title="0"
    data-vimeo-portrait="0"
  />;
};

