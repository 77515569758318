import React, {ChangeEvent, FunctionComponent} from 'react';
import {IOverlayInput} from 'components/overlay/interfaces';

import './styles.scss';

interface IDCheckboxList {
  control: IOverlayInput
  onChange: (value: string) => void
}

export const DRadiobutton: FunctionComponent<IDCheckboxList> = ({
  control,
  onChange
}: IDCheckboxList) => {
  function handleChangeRadio(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value);
  }

  return <div className="d-radio">
    {
      control.list?.map((radio) => {
        return <>
          <input
            className="d-radio__field"
            id={radio.id.toString()}
            key={`input${radio.id}`}
            type={control.type}
            name={control.name}
            value={radio.value}
            onChange={handleChangeRadio}
          />
          <label
            key={`label${radio.id}`}
            className="d-radio__label"
            htmlFor={radio.id.toString()}>
            {radio.label}
          </label>
        </>;
      })
    }
  </div>;
};
