import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {IStore} from 'redux/interface';
import {clearErrorAction, finishLoadingAction, setErrorAction, startLoadingAction} from 'redux/status/actions';
import {getMediaUrlApi} from '../api/media';
import {mediaLoadedAction} from './actions';

export const loadMediaAction = (fileId: number, callback?: (url: string) => void): ThunkAction<void, IStore, unknown, Action> => (async (dispatch) => {
  const actionKey = fileId?.toString();

  dispatch(startLoadingAction(actionKey));
  dispatch(clearErrorAction(actionKey));
  try {
    const {data: {value: fileUrl} } = await getMediaUrlApi(fileId);

    dispatch(mediaLoadedAction({
      mediaUrl: fileUrl,
      key: fileId
    }));
    if (callback) {
      callback(fileUrl);
    }
    dispatch(finishLoadingAction(actionKey));
  } catch (e) {
    console.log('loadMediaAction', e);
    dispatch(setErrorAction({
      key: actionKey,
      value: e
    }));
    dispatch(finishLoadingAction(actionKey));
  }
});
