import React, {ChangeEvent, FunctionComponent} from 'react';
import {IOverlayInput} from 'components/overlay/interfaces';

import './styles.scss';

interface IDCheckboxList {
  control: IOverlayInput
  onChange: (value: string) => void
}

export const DInput: FunctionComponent<IDCheckboxList> = ({
  control,
  onChange
}: IDCheckboxList) => {
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value);
  }

  return <input
    className="d-input d-input__field"
    type="text"
    placeholder={control.placeholder}
    name={control.name}
    id={control.name}
    value={control.value}
    onChange={handleChangeInput}
  />;
};
