import React, {CSSProperties} from 'react';
import cn from 'classnames';
import './styles.scss';
import {ButtonVariant} from './ButtonVariantEnum';

interface IGritxButtonProps {
  title: string;
  variant: ButtonVariant;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  style?: CSSProperties
}

export const GritxButton = ({
  title,
  variant,
  className,
  disabled = false,
  onClick,
  style
}: IGritxButtonProps): JSX.Element => {
  return <button
    className={cn(
      'gritx-btn',
      {'gritx-btn-primary': variant === ButtonVariant.Primary},
      {'gritx-btn-secondary': variant === ButtonVariant.Secondary},
      {'gritx-btn-light': variant === ButtonVariant.Light},
      {'gritx-btn-outline': variant === ButtonVariant.Outline},
      className
    )}
    style={style}
    disabled={disabled}
    onClick={onClick}>
    {title}
  </button>;
};
