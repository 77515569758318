import {GritxToastOptions} from './GritxToastOptions';
import {ToastKey} from './ToastKeyEnum';

const toastOptionsMap: Record<ToastKey, GritxToastOptions> = {
  [ToastKey.GetUserData]:
    {title: 'gritx.toasts.auth.wrongUserUpdate', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetBotAvatars]:
    {title: 'gritx.toasts.auth.wrongBotAvatar', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetUserAvatars]:
    {title: 'gritx.toasts.auth.wrongUserAvatar', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.UpdateUserProfileAction]:
    {title: 'gritx.toasts.auth.wrongProfileUpdate', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadContentAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetLibFilters]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadArticleAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadMaterialsContentAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.AddFavorite]:
    {title: 'gritx.toasts.content.wrongAddFavoriteCard', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.RemoveFavorite]:
    {title: 'gritx.toasts.content.wrongRemoveFavoriteCard', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.AddMaterial]:
    {title: 'gritx.toasts.content.wrongNotAbleCard', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.RemoveMaterial]:
    {title: 'gritx.toasts.content.wrongRemoveCard', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadBackpackContentAction]:
    {title: 'gritx.toasts.content.wrongLoadBackpack', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadMaterialsTemplates]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.SendMaterialsAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.SaveJournalAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.UploadAttachAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.SendFeedbackAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.JoinNewsletterAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadTranslation]:
    {title: 'gritx.toasts.translation.wrongLoad', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.ChangeLocale]:
    {title: 'gritx.toasts.translation.wrongChange', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetToken]:
    {title: 'gritx.toasts.chatBot.wrongSomething', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.FetchMedia]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetBotList]:
    {title: 'gritx.toasts.chatBot.wrongSomething', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetMainDialog]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.SetDialogLanguage]:
    {title: 'gritx.toasts.chatBot.wrongLanguageChange', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetXpeditionList]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.StartXpedition]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.StartInterview]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true}
};

export const getToastOption = (key: ToastKey): GritxToastOptions => toastOptionsMap[key];
