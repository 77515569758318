import React, {FunctionComponent} from 'react';
import cn from 'classnames';

import './styles.scss';

interface IUserMessage {
  sendMessage: string | undefined,
  hideUserMessage: boolean
}

export const UserMessage: FunctionComponent<IUserMessage> = ({sendMessage, hideUserMessage}: IUserMessage) => {
  return sendMessage
    ? <div className={cn('bot-chat__message bot-chat__message--right', {hidden: hideUserMessage})}>
      <div className="bot-chat__message-value">{sendMessage}</div>
    </div>
    : null;
};
