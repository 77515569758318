import React, {FunctionComponent} from 'react';
import cn from 'classnames';
import {IOverlayInput} from 'components/overlay/interfaces';

import './styles.scss';

interface IDButton {
  control: IOverlayInput
  onChange: (value: string) => void
}

export const DButton: FunctionComponent<IDButton> = ({
  control,
  onChange
}: IDButton) => {
  function handleChangeButton(value: string) {
    return () => {
      onChange(value);
    };
  }

  return <>
    {
      control.list?.map((button) => {
        return <button
          key={button.id}
          className={cn(
            'd-button', {
              'd-button--checked': control.value === button.value
            }
          )}
          onClick={handleChangeButton(button.value)}
        >
          {button.label}
        </button>;
      })
    }
  </>;
};
