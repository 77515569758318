import axios, {AxiosResponse} from 'axios';
import {IUserProfile} from '../auth/interfaces';

export const getUserProfileApi = (): Promise<AxiosResponse<IUserProfile>> => {
  return axios.get<IUserProfile>(`${process.env.REACT_APP_API_URL}/user/profile`);
};
export const updateUserProfileApi = (profile: IUserProfile): Promise<AxiosResponse<IUserProfile>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/user/profile/update`,
    profile
  );
};
