import React, {FunctionComponent, useEffect, useState} from 'react';

import GritxButton from 'components/gritx-button';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import Loader from 'components/loader';
import XpeditionOverlay from 'components/xpedition-overlay';

import {BotActionType, IBotAction, IBotAnswer, MessageType} from 'utils/hooks/use-chat-bot/interfaces';

import './styles.scss';

interface IBotChat {
  groupAnswers: IBotAnswer[]
  singleAnswers: IBotAnswer[]
  botAnswer: IBotAnswer[]
  onClickAction: (actionButton: IBotAction) => void
  onChangeOverlayAnswer: (answer: IBotAction | null) => void
}

export const BotMessages: FunctionComponent<IBotChat> = ({
  groupAnswers,
  singleAnswers,
  botAnswer,
  onClickAction,
  onChangeOverlayAnswer
}: IBotChat) => {
  const [isShowXpeditionOverlay, setIsShowXpeditionOverlay] = useState(false);

  useEffect(() => {
    setIsShowXpeditionOverlay(false);
  }, [botAnswer]);

  function handleClickAction(actionButton: IBotAction) {
    return () => {
      onClickAction(actionButton);
    };
  }

  function handleChangeOverlayAnswer(answer: IBotAction | null) {
    onChangeOverlayAnswer(answer);
  }

  function showXpeditionOverlay() {
    setIsShowXpeditionOverlay(true);
  }

  function renderBotAnswerButtons(answer: IBotAnswer) {
    return answer.keyboard?.buttons.length ? <div className="bot-chat__message-buttons">{
      answer.keyboard.buttons.map((button, i) => {
        if (!button) {
          return null;
        }
        if (button.actionType === 'OVERLAY') {
          if (!isShowXpeditionOverlay) {
            showXpeditionOverlay();
          }

          return null;
        }

        if (button.actionType === BotActionType.OpenURL) {
          return <a
            key={i}
            href={button.actionUrl}
            target="_blank"
            rel="noreferrer"
            className="bot-chat__chat-button--link"
            onClick={handleClickAction(button)}
          >
            {button.text}
          </a>;
        }

        return <GritxButton
          key={i}
          title={button.text as string}
          variant={ButtonVariant.Outline}
          className="bot-chat__chat-button"
          onClick={handleClickAction(button)}
        />;
      })}</div> : null;
  }

  function renderBotAnswerLinks(answer: IBotAnswer) {
    return answer.urls?.length
      ? answer.urls.map(url => {
        return <div key={url}>
          <a className="bot-chat__message-url" href={url} target={'_blank'} rel={'noreferrer'}>{url}</a>
        </div>;
      })
      : null;
  }

  function renderMessageCard(answers: IBotAnswer[]) {
    const picture = answers[0]?.picture?.fileUrl;
    const title = answers[1]?.text;
    const desc = answers[2]?.text;
    const buttons = groupAnswers[3]?.keyboard?.buttons || groupAnswers[2]?.keyboard?.buttons || [];

    return <>
      <div className="message-card">
        <div className="message-card__wrapper">
          <div className="message-card__title">
            {title}
          </div>
          <div className="message-card__content">
            {picture
              ? <img src={picture} role="presentation" alt="" className="message-card__picture"/>
              : <Loader className="message-card__picture message-card__picture-loader" nested/>}
            <div className="message-card__description">{desc}</div>
          </div>
        </div>
      </div>
      {buttons.length !== 0
      && <div className="bot-chat__message-buttons">
        {buttons.map((button, i) => {
          if (button && button.actionType !== 'OVERLAY') {
            return <GritxButton
              key={i}
              title={button?.text as string}
              variant={i ? ButtonVariant.Outline : ButtonVariant.Primary}
              className="bot-chat__chat-button bot-chat__group-button"
              onClick={handleClickAction(button)}
            />;
          }

          return null;
        })}
      </div>}
    </>;
  }

  return <>
    {
      singleAnswers.map((answer) => {
        if (answer.contentId && answer.type === MessageType.Picture) {
          return <>
            <div
              key={answer.messageId}
              className="bot-chat__message bot-chat__message--left">
              <div className="bot-chat__message-value">
                {answer.picture?.fileUrl
                  ? <img src={answer.picture.fileUrl} role="presentation" alt="" className="bot-chat__picture"/>
                  : <Loader className="bot-chat__picture-loader" nested/>}
              </div>
            </div>
          </>;
        }

        return answer.type !== MessageType.Picture && (answer.text?.trim() || answer.keyboard?.buttons.length || answer.urls?.length)
          ? <div key={answer.messageId} className="bot-chat__message bot-chat__message--left">
            <div className="bot-chat__message-value">
              <span dangerouslySetInnerHTML={{
                __html: answer.text.trim()
              }}/>
              {renderBotAnswerButtons(answer)}
              {renderBotAnswerLinks(answer)}
            </div>
          </div> : null;
      })
    }
    {
      isShowXpeditionOverlay
      && Boolean(botAnswer.length)
      && <XpeditionOverlay
        botAnswers={botAnswer}
        onChange={handleChangeOverlayAnswer}
      />
    }
    {groupAnswers.length !== 0
    && <div className="bot-chat__message bot-chat__message--left">
      <div className="bot-chat__message-value bot-chat__group">
        {renderMessageCard(groupAnswers)}
      </div>
    </div>
    }
  </>;
};
