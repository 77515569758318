import {
  loadArticleAction,
  loadBackpackContentAction,
  loadLibContentAction,
  loadLibFiltersAction,
  loadMaterialsContentAction,
  loadMaterialsTemplatesAction, saveJournalAction,
  sendMaterialsAction
} from './content/contentAsyncActions';

export const getTranslations = 'GET_TRANSLATIONS';
export const getLibFilters = 'GET_LIBRARY_FILTERS';
export const getLibContent = 'GET_LIBRARY_CONTENT';
export const getBackpackContent = 'GET_BACKPACK_CONTENT';
export const getMaterialsContent = 'GET_MATERIALS_CONTENT';
export const getArticle = 'GET_ARTICLE';
export const sendFeedback = 'SEND_FEEDBACK';
export const getTemplates = 'GET_TEMPLATES';
export const sendMaterials = 'SEND_MATERIALS';
export const saveJournal = 'SAVE_JOURNAL';

export const libraryAsyncActions = {
  [getLibFilters]: loadLibFiltersAction,
  [getLibContent]: loadLibContentAction
};

export const backpackAsyncActions = {
  [getBackpackContent]: loadBackpackContentAction
};

export const materialsAsyncActions = {
  [getMaterialsContent]: loadMaterialsContentAction,
  [getTemplates]: loadMaterialsTemplatesAction,
  [sendMaterials]: sendMaterialsAction
};

export const contentAsyncActions = {
  [getArticle]: loadArticleAction,
  [saveJournal]: saveJournalAction
};

export const chatAsyncActions = {
  [getArticle]: loadArticleAction
};
