import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {Avatar} from 'components/avatar/Avatar';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import {GritxButton} from 'components/gritx-button/GritxButton';
import {Input} from 'components/input/Input';
import {ModalWindow} from 'components/modal-window/ModalWindow';
import {AvatarList} from 'components/avatar-list/AvatarsList';
import {InputPhone} from 'components/input-phone/InputPhone';
import {isValidPhoneNumber} from 'react-phone-number-input';

import {IUserProfile} from 'redux/auth/interfaces';
import {IStore} from 'redux/interface';
import {logoutAction, updateUserProfileAction} from 'redux/auth/authAsyncActions';
import {changeLocale} from 'redux/translation/translationAsyncActions';
import {libClearSelectedFiltersAction} from 'redux/content/actions';

import {isValidEmail} from 'utils/helpers/validators';
import {languages} from 'utils/constants/constants';
import {useLoadFile} from 'utils/hooks/useLoadFile';
import {useChatBot} from 'utils/hooks/use-chat-bot/useChatBot';
import {BotType} from 'utils/hooks/use-chat-bot/interfaces';

import UserIcon from 'assets/image/user-settings/userDefaultAvatar.png';
import BotIcon from 'assets/image/user-settings/BotDefaultAvatar.png';
import {NavLink} from 'react-router-dom';

export const SettingsForm = (): JSX.Element => {
  const {auth: {userProfile} } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    getFileUrl
  } = useLoadFile();

  const {
    dialogMethods: {setDialogLanguage}
  } = useChatBot(BotType.Chat);
  const [firstName, setFirstName] = useState(userProfile?.firstName);
  const [lastName, setLastName] = useState(userProfile?.lastName);
  const [email, setEmail] = useState(userProfile?.email);
  const [phone, setPhone] = useState(userProfile?.phone || '');
  const [modalAvatarsFlag, setModalAvatarsFlag] = useState<null | 'user' | 'bot'>(null);
  const [isRequiredEmail, setIsRequiredEmail] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);

  useEffect(() => {
    if (userProfile?.email) {
      setIsRequiredEmail(true);
    }
  }, [userProfile?.email]);

  function handleChangeFirstName(value: string) {
    setFirstName(value);
    setIsChangeForm(true);
  }

  function handleChangeLastName(value: string) {
    setLastName(value);
    setIsChangeForm(true);
  }

  function handleChangeEmail(value: string) {
    setEmail(value);
    setIsChangeForm(true);
  }

  function handleChangePhone(value: string) {
    const newPhone = value || '';

    setPhone(value);
    if (newPhone !== userProfile?.phone) {
      setIsChangeForm(true);
    }
  }

  const getValidFormStatus = (): boolean => {
    const getValidEmailValue = email && isValidEmail(email);
    const getValidPhoneValue = !phone || isValidPhoneNumber(phone);

    return !isChangeForm || firstName?.trim() === '' || lastName?.trim() === '' || !getValidEmailValue || !getValidPhoneValue;
  };

  function handleChangeLang(lang: string) {
    return async () => {
      setDialogLanguage(lang);
      dispatch(changeLocale(lang));
      dispatch(libClearSelectedFiltersAction());
      dispatch(updateUserProfileAction({
        ...userProfile,
        language: lang
      } as IUserProfile));
    };
  }

  function handleSave() {
    setFirstName(firstName?.trim());
    setLastName(lastName?.trim());

    dispatch(updateUserProfileAction({
      ...userProfile,
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      email: email,
      phone: phone || ''
    } as IUserProfile));

    setIsChangeForm(false);
  }

  function handleSignOut() {
    dispatch(logoutAction());
  }

  function handleCloseModal() {
    setModalAvatarsFlag(null);
  }

  function openModal(type: 'user' | 'bot') {
    return () => setModalAvatarsFlag(type);
  }

  return (
    <div className="settings__content">
      <div className="settings__avatars">
        <div className="avatar">
          <Avatar
            editable
            handleClick={openModal('user')}
            imgUrl={userProfile?.userAvatarId ? getFileUrl(userProfile.userAvatarId) : UserIcon}
          />
          <p className="avatar__text">
            <FormattedMessage
              id="gritx.user-settings.avatarUser"
              defaultMessage="Me"
            />
          </p>
        </div>
        <div className="avatar">
          <Avatar
            editable
            handleClick={openModal('bot')}
            imgUrl={userProfile?.dialogAvatarId ? getFileUrl(userProfile.dialogAvatarId) : BotIcon}
          />
          <p className="avatar__text avatar__text-bot">
            <FormattedMessage
              id="gritx.user-settings.avatarBot"
              defaultMessage="MY GritX"
            />
          </p>
        </div>
      </div>
      <div className="settings__info info">
        <div className="info__wrap">
          <div className="info__language">
            {
              languages.map((item, index) => {
                return (
                  <GritxButton
                    key={index}
                    title={item.title}
                    variant={userProfile?.language === item.value
                      ? ButtonVariant.Primary
                      : ButtonVariant.Outline
                    }
                    onClick={handleChangeLang(item.value)}
                    className="info__language-btn"
                  />
                );
              })
            }
          </div>
          <div className="info__content">
            <Input
              className="info__item"
              label={intl.formatMessage({
                id: 'gritx.user-settings.firstName',
                defaultMessage: 'First Name'
              })}
              required
              maxLength={100}
              type="text"
              placeholder={intl.formatMessage(({
                id: 'gritx.user-settings.firstName.placeholder',
                defaultMessage: 'Please, enter your First Name'
              }))}
              value={firstName}
              onChange={handleChangeFirstName}
            />
            <Input
              className="info__item"
              label={intl.formatMessage({
                id: 'gritx.user-settings.lastName',
                defaultMessage: 'Last Name'
              })}
              required
              maxLength={100}
              type="text"
              placeholder={intl.formatMessage(({
                id: 'gritx.user-settings.lastName.placeholder',
                defaultMessage: 'Please, enter your Last Name'
              }))}
              value={lastName}
              onChange={handleChangeLastName}
            />
            <Input
              className="info__item"
              label={intl.formatMessage({
                id: 'gritx.user-settings.email',
                defaultMessage: 'Email'
              })}
              required={isRequiredEmail}
              type="email"
              placeholder={intl.formatMessage(({
                id: 'gritx.user-settings.email.placeholder',
                defaultMessage: 'Please, enter your Email'
              }))}
              value={email}
              onChange={handleChangeEmail}
            />
            <InputPhone
              labelClassName="info__phone"
              userPhoneNumber={phone}
              onChange={handleChangePhone}
            />
            <div className="settings__save">
              <GritxButton
                className="settings__save-btn"
                title={intl.formatMessage({
                  id: 'gritx.user-settings.buttonSave',
                  defaultMessage: 'Save'
                })}
                disabled={getValidFormStatus()}
                variant={ButtonVariant.Primary}
                onClick={handleSave}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="settings__log-out">
        <NavLink
          to={'/logout'}
        >
          <GritxButton
            className="settings__log-out-btn"
            title={intl.formatMessage({
              id: 'gritx.user-settings.buttonLogOut',
              defaultMessage: 'Log Out'
            })}
            variant={ButtonVariant.Outline}
            onClick={handleSignOut}
          />
        </NavLink>
      </div>
      <ModalWindow
        className="settings__modal"
        show={Boolean(modalAvatarsFlag)}
        onHide={handleCloseModal}>
        <AvatarList
          type={modalAvatarsFlag}
          closeModal={setModalAvatarsFlag}
        />
      </ModalWindow>
    </div>
  );
};
