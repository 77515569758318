import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import {FormattedMessage, useIntl} from 'react-intl';

import {loadArticleAction} from 'redux/content/contentAsyncActions';
import {closeArticle} from 'redux/content/actions';
import {IStore} from 'redux/interface';

import GritxButton from 'components/gritx-button';
import Loader from 'components/loader';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import BotIcon from 'assets/image/user-settings/BotDefaultAvatar.png';

import {BotActionType, BotType, IBotAction, IBotAnswer, MessageType} from 'utils/hooks/use-chat-bot/interfaces';
import {useChatBot} from 'utils/hooks/use-chat-bot/useChatBot';
import {useLoadFile} from 'utils/hooks/useLoadFile';

import {XpeditionList} from './xpedition/XpeditionList';
import {BotContent} from './BotContent';
import {BotLoader} from './BotLoader';
import {UserMessage} from './UserMessage';
import {BotMessages} from './BotMessages';

import './styles.scss';

interface IBotChat {
  mode: BotType;
}

export const BotChat: FunctionComponent<IBotChat> = ({mode}: IBotChat) => {
  const intl = useIntl();
  const chatLogRef = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLTextAreaElement>();
  const [groupAnswers, setGroupAnswers] = useState<IBotAnswer[]>([]);
  const [singleAnswers, setSingleAnswers] = useState<IBotAnswer[]>([]);
  const [sendMessage, setSendMessage] = useState<string>();
  const [input, setInput] = useState<string>();
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [hideUserMessage, setHideUserMessage] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [showLoading, setShowLoading] = useState(false);
  // -----xpedition----
  const [isShowXpeditionOverlay, setIsShowXpeditionOverlay] = useState(false);
  const [xpeditionOverlayAnswer, setXpeditionOverlayAnswer] = useState<IBotAction | null>(null);
  // ----------
  const chatBot = useChatBot(mode);
  const {auth: {userProfile} } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {getFileUrl} = useLoadFile();

  const scrollToBottom = () => {
    chatLogRef?.current?.scrollTo(0, chatLogRef.current.scrollHeight);
  };

  function hideUserMessageTimer(hasAnswers: boolean) {
    if (timer) {
      clearTimeout(timer);
      setTimer(undefined);
    }
    if (hasAnswers) {
      setTimer(setTimeout(() => setHideUserMessage(true), 3000));
    }
  }

  useEffect(() => {
    setIsShowXpeditionOverlay(false);
    if (chatBot.botAnswer.length || sendMessage) {
      setShowMessages(true);
    }
    setShowLoading(false);

    hideUserMessageTimer(chatBot.botAnswer.length !== 0);

    setGroupAnswers(chatBot.botAnswer.filter(answer => answer.contentType));
    setSingleAnswers(chatBot.botAnswer.filter(answer => !answer.contentType));
    setIsDisabledInput(isShowXpeditionOverlay || Boolean(chatBot.botAnswer.find(answer => answer.keyboard?.buttons.length)));
  }, [chatBot.botAnswer, sendMessage]);

  useEffect(() => {
    if ((chatBot.isLoading && showLoading) || chatBot.botAnswer) {
      scrollToBottom();
    }
  }, [chatBot.isLoading, showLoading, chatBot.botAnswer]);

  useEffect(() => {
    if (mode === BotType.Xpedition) {
      chatBot.xpedition.getXpeditionList();
    } else if (userProfile?.greetingContentId) {
      dispatch(loadArticleAction(userProfile.greetingContentId.toString()));
    }
  }, [userProfile]);

  const pushUserMessage = (text: string) => {
    setInput('');
    setSendMessage('');
    setHideUserMessage(false);

    chatBot.sendMessage(MessageType.Text, {text});
    setTimeout(() => setSendMessage(text), 100);
    setTimeout(() => setShowLoading(true), Math.floor(Math.random() * 1000) + 300);
  };

  function handleSendOverlay() {
    setIsShowXpeditionOverlay(false);
    if (!xpeditionOverlayAnswer) {
      return;
    }
    const actionButton = xpeditionOverlayAnswer;

    setXpeditionOverlayAnswer(null);
    chatBot.sendMessage(MessageType.Action, {
      button: {
        actionType: actionButton.actionType,
        actionBody: actionButton.actionBody,
        actionUrl: actionButton.actionUrl,
        serialNumber: actionButton.serialNumber,
        text: actionButton.text
      }
    });
  }

  const handleSend = () => {
    if (isShowXpeditionOverlay) {
      dispatch(closeArticle());
      handleSendOverlay();
    } else {
      pushUserMessage((input as string).trim());
    }
  };

  const keydownHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.shiftKey) {
        const {
          selectionStart,
          selectionEnd
        } = event.currentTarget;

        const newValue = `${event.currentTarget.value?.substring(0, selectionStart)}\n${event.currentTarget.value?.substring(selectionEnd)}`;

        setInput(newValue);
        if (refInput.current) {
          refInput.current.value = newValue;
          refInput.current.selectionStart = selectionStart + 1;
          refInput.current.selectionEnd = selectionStart + 1;
        }
      } else if (event.currentTarget.value.trim()) {
        handleSend();
      }
    }
  };

  function handleHelp() {
    pushUserMessage('Help');
  }

  function handleClickAction(actionButton: IBotAction) {
    const actionBody = JSON.parse(actionButton.actionBody);

    switch (actionButton.actionType) {
      case BotActionType.ContentView:
        dispatch(loadArticleAction(actionBody.addActionBody.contentInfo.id));
        setSendMessage('');
        setIsShowXpeditionOverlay(false);

        chatBot.sendMessage(MessageType.Action, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      case BotActionType.OpenURL:
      case BotActionType.ReturnValue:
      case BotActionType.Schedule:
        setSendMessage('');
        setIsShowXpeditionOverlay(false);
        chatBot.sendMessage(MessageType.Action, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      default:
        break;
    }
  }

  function handleStartXpedition(botId: number) {
    chatBot.xpedition.startXpedition(botId);
  }

  async function handleRemoveDialog(dialogId: number) {
    await chatBot.dialogMethods.removeDialog(dialogId);
    chatBot.xpedition.getXpeditionList();
  }

  // ----------

  function handleChangeOverlayAnswer(answer: IBotAction | null) {
    setIsShowXpeditionOverlay(true);
    setXpeditionOverlayAnswer(answer);
  }

  if (mode === BotType.Xpedition && !chatBot.currentDialog) {
    if (chatBot.xpedition.xpeditionList.length === 0) {
      return <Loader nested/>;
    }
    const {xpeditionList} = chatBot.xpedition;

    return <XpeditionList
      xpeditionList={xpeditionList}
      onStart={handleStartXpedition}
      onRemove={handleRemoveDialog}
    />;
  }

  function getButtonDisabledStatus(): boolean {
    return isShowXpeditionOverlay ? !xpeditionOverlayAnswer : !input?.trim();
  }

  function getButtonTitle(): string {
    if (isShowXpeditionOverlay && xpeditionOverlayAnswer) {
      return intl.formatMessage({
        id: 'gritx.common.button.next',
        defaultMessage: 'Next'
      });
    }

    return intl.formatMessage({
      id: 'gritx.common.button.send',
      defaultMessage: 'Send'
    });
  }

  function getBotAvatar() {
    return userProfile?.dialogAvatarId ? getFileUrl(userProfile.dialogAvatarId) : BotIcon;
  }

  return <div className="bot">
    <div className="bot__wrapper">
      <div className="bot__window">
        <div className="bot-chat">
          <div className="bot-chat__toolbar">
            <div className="chat__bot-avatar-wrapper">
              <img
                src={getBotAvatar()}
                alt=""
                className="chat__bot-avatar"
              />
            </div>
            {
              mode !== BotType.Xpedition
              && <GritxButton
                title={intl.formatMessage({
                  id: 'gritx.chat.helpButton',
                  defaultMessage: 'Help'
                })}
                variant={ButtonVariant.Outline}
                className="chat__help-btn"
                onClick={handleHelp}
              />
            }
          </div>
          {showMessages
            ? <div className="bot-chat__wrapper bot-chat__log" ref={chatLogRef}>
              <UserMessage sendMessage={sendMessage} hideUserMessage={hideUserMessage}/>
              <BotMessages
                groupAnswers={groupAnswers}
                singleAnswers={singleAnswers}
                botAnswer={chatBot.botAnswer}
                onClickAction={handleClickAction}
                onChangeOverlayAnswer={handleChangeOverlayAnswer}
              />
              <BotLoader isLoading={chatBot.isLoading && showLoading}/>
            </div>
            : <div className="bot-chat__wrapper">
              <div className="bot-chat__start-message">
                <FormattedMessage
                  id={'gritx.chat.startMessage'}
                  defaultMessage={'Hi there! Let`s talk!'}
                />
              </div>
            </div>
          }
          <div className="bot-chat__form">
            <TextareaAutosize
              ref={(tag: HTMLTextAreaElement) => {
                refInput.current = tag;
              }}
              className="bot-chat__input"
              maxLength={2000}
              placeholder={intl.formatMessage({
                id: 'gritx.chat.inputPlaceholder',
                defaultMessage: 'type a message'
              })}
              maxRows={9}
              value={input}
              onKeyDown={keydownHandler}
              onChange={ev => {
                setInput(ev.target.value);
              }}
              disabled={isDisabledInput}
            />
            <GritxButton
              title={getButtonTitle()}
              variant={ButtonVariant.Primary}
              disabled={getButtonDisabledStatus()}
              className="bot-chat__send-btn"
              onClick={handleSend}
            />
          </div>
        </div>
      </div>
      <div className="bot__content">
        <BotContent botAnswer={chatBot.botAnswer}/>
      </div>
    </div>
  </div>;
};
