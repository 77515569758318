import React, {ChangeEvent, FunctionComponent} from 'react';
import {IOverlayInput} from 'components/overlay/interfaces';
import './styles.scss';

interface IDCheckboxList {
  control: IOverlayInput
  onChange: (value: boolean) => void
}

export const DCheckboxList: FunctionComponent<IDCheckboxList> = ({
  control,
  onChange
}: IDCheckboxList) => {
  function handleChangeCheckbox(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.checked);
  }

  return <div className="d-checkbox">
    <input
      className="d-checkbox__field"
      id={control.id.toString()}
      key={`input${control.id}`}
      type={control.type}
      checked={control.value !== 'No'}
      name={control.name}
      onChange={handleChangeCheckbox}
    />
    <label
      key={`label${control.id}`}
      className="d-checkbox__label"
      htmlFor={control.id.toString()}>
      {control.label}
    </label>
  </div>;
};
