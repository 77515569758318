import React, {useEffect, useState} from 'react';

import {
  getBotAvatars,
  getUserAvatars,
  updateUserProfileAction
} from 'redux/auth/authAsyncActions';
import {SET_BOT_AVATARS, SET_USER_AVATARS} from 'redux/auth/actions';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from 'redux/interface';
import {IAvatar} from 'redux/auth/interfaces';

import {Avatar} from 'components/avatar/Avatar';
import Loader from 'components/loader';

import UserIcon from 'assets/image/user-settings/user-ico.svg';

import './styles.scss';

interface IAvatarListProps {
  type: null | 'user' | 'bot';
  closeModal: (props: null) => void;
}

export const AvatarList = ({
  type,
  closeModal
}: IAvatarListProps): JSX.Element => {
  const {
    auth: {userAvatars, botAvatars, userProfile}
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();
  const [avatarsList, setAvatarsList] = useState<IAvatar[]>([]);

  useEffect(() => {
    dispatch(getUserAvatars());
    dispatch(getBotAvatars());
  }, []);

  useEffect(() => {
    let avatars: IAvatar[] = [];

    if (userAvatars) {
      avatars = [...userAvatars];
    }
    if (botAvatars) {
      avatars = [...avatars, ...botAvatars];
    }
    setAvatarsList(avatars);
  }, [userAvatars, botAvatars]);

  function handleChangeAvatar(fileId: number) {
    return () => {
      if (type === 'user' && userProfile) {
        dispatch(
          updateUserProfileAction({
            ...userProfile,
            userAvatarId: fileId
          })
        );

        closeModal(null);

        return;
      }
      if (type === 'bot' && userProfile) {
        dispatch(
          updateUserProfileAction({
            ...userProfile,
            dialogAvatarId: fileId
          })
        );

        closeModal(null);

        return;
      }
      console.log('default click action');
    };
  }

  return (
    <>
      {avatarsList.length && avatarsList.filter(item => item.fileUrl).length === avatarsList.length
        ? <div className="avatars__content">
          {avatarsList.map((item, index) => {
            return (
              <Avatar
                key={index}
                imgUrl={item.fileUrl}
                imgText={item.text}
                handleClick={handleChangeAvatar(item.fileId)}
              />
            );
          })}
        </div>
        : <Loader nested/>
      }
    </>
  );
};
