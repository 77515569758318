import React, {useEffect, ReactElement, useState} from 'react';
import {IntlProvider} from 'react-intl';
import {Switch, Route, Redirect, RouteProps, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';

import {IStore} from 'redux/interface';
import {getAuthorizationAction} from 'redux/auth/authAsyncActions';
import {changeLocale} from 'redux/translation/translationAsyncActions';
import {firstAuthorizedRoute, Routes, routeTranslationKeys, TranslationKeys} from 'utils/constants/routeConstants';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {SystemLanguage} from 'utils/constants/constants';

import Backpack from 'pages/backpack';
import Callback from 'pages/callback';
import Chat from 'pages/chat';
import Content from 'pages/content';
import Error from 'pages/error';
import Home from 'pages/home';
import Layout from 'components/layout';
import Library from 'pages/library';
import Materials from 'pages/materials';
import UserSettings from 'pages/user-settings';
import Xpedition from 'pages/xpedition';
import Logout from 'pages/logout';
import {MobileView} from 'components/mobile-view/MobileView';
import {Bounce, ToastContainer} from 'react-toastify';

const PrivateRoute: React.FunctionComponent<RouteProps> = (
  {
    children,
    ...rest
  }
) => {
  const {auth0User} = useSelector((state: IStore) => state.auth);

  return (
    <Route
      {...rest}
      render={
        ({location}) => {
          return auth0User
            ? (<Layout>{children as ReactElement}</Layout>)
            : (
              <Redirect
                to={{
                  pathname: Routes.Home,
                  state: {from: location}
                }}
              />
            );
        }
      }
    />
  );
};

const App: React.FunctionComponent<RouteProps> = () => {
  const {
    translation: {
      locale,
      translations
    },
    auth: {
      userProfile
    }
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const [isMobileView, setIsMobileView] = useState(false);

  useGetTranslations({
    translationKey: TranslationKeys.Common
  });
  useGetTranslations({
    translationKey: TranslationKeys.Toasts
  });
  useGetTranslations({
    translationKey: routeTranslationKeys[firstAuthorizedRoute]
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }, [pathname]);

  useEffect(() => {
    if (userProfile && userProfile.language !== SystemLanguage.English) {
      dispatch(changeLocale(userProfile.language));
    }
  }, [userProfile, userProfile?.language]);

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|Mac|Macintosh|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobileView(true);
    }
    if (/Mac|Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints === 0) { // macbooks
      setIsMobileView(false);
    }
    dispatch(getAuthorizationAction());
  }, []);

  return (
    <IntlProvider locale={locale} messages={translations[locale]} onError={(e) => console.log('IntlError', e)}>
      <ToastContainer
        role="alert"
        position="bottom-left"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        hideProgressBar
        limit={5}
        autoClose={30000}
        transition={Bounce}
      />
      <Helmet>
        <html lang={locale}/>
      </Helmet>
      {
        isMobileView
          ? <MobileView/>
          : <Switch>
            <Route exact path={Routes.Home}>
              <Layout hideSeparator={true}>
                <Home/>
              </Layout>
            </Route>
            <Route exact path={Routes.Logout}>
              <Layout>
                <Logout/>
              </Layout>
            </Route>
            <Route exact path={Routes.Callback}>
              <Layout>
                <Callback/>
              </Layout>
            </Route>
            <PrivateRoute path={Routes.Library}>
              <Library/>
            </PrivateRoute>
            <PrivateRoute path={Routes.Materials}>
              <Materials/>
            </PrivateRoute>
            <PrivateRoute path={Routes.Backpack}>
              <Backpack/>
            </PrivateRoute>
            <PrivateRoute path={Routes.Chat}>
              <Chat/>
            </PrivateRoute>
            <PrivateRoute path={Routes.Xpedition}>
              <Xpedition/>
            </PrivateRoute>
            <PrivateRoute path={Routes.Settings}>
              <UserSettings/>
            </PrivateRoute>
            <PrivateRoute path={Routes.Content}>
              <Content/>
            </PrivateRoute>
            <Route path={'*'}>
              <Layout>
                <Error/>
              </Layout>
            </Route>
          </Switch>
      }
    </IntlProvider>
  );
};

export default App;
