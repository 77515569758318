export enum ToastKey {
  GetUserData,
  GetBotAvatars,
  GetUserAvatars,
  UpdateUserProfileAction,
  LoadContentAction,
  GetLibFilters,
  LoadArticleAction,
  LoadMaterialsContentAction,
  AddFavorite,
  RemoveFavorite,
  AddMaterial,
  RemoveMaterial,
  LoadBackpackContentAction,
  LoadMaterialsTemplates,
  SendMaterialsAction,
  SaveJournalAction,
  UploadAttachAction,
  SendFeedbackAction,
  JoinNewsletterAction,
  LoadTranslation,
  ChangeLocale,
  GetToken,
  FetchMedia,
  GetBotList,
  GetMainDialog,
  SetDialogLanguage,
  GetXpeditionList,
  StartXpedition,
  StartInterview
}
