import React, {FunctionComponent, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {IStore} from 'redux/interface';
import {closeArticle} from 'redux/content/actions';
import {getArticle} from 'redux/pageAsyncActions';
import {loadArticleAction} from 'redux/content/contentAsyncActions';

import Loader from 'components/loader';
import Article from 'components/article';

import {IBotAnswer} from 'utils/hooks/use-chat-bot/interfaces';

import './styles.scss';

interface IBotContent {
  botAnswer: IBotAnswer[]
}

export const BotContent: FunctionComponent<IBotContent> = ({botAnswer}: IBotContent) => {
  const dispatch = useDispatch();
  const {
    content: {article},
    status: {loading},
    auth: {userProfile}
  } = useSelector((state: IStore) => state);
  const [pictureAnswer, setPictureAnswer] = useState<IBotAnswer[]>([]);

  useEffect(() => {
    const pictures = botAnswer.filter(item => {
      return !item.contentId && item.picture;
    });

    setPictureAnswer(pictures);
  }, [botAnswer]);

  function handleCloseArticle() {
    if (userProfile?.greetingContentId) {
      dispatch(loadArticleAction(userProfile.greetingContentId.toString()));
    } else {
      dispatch(closeArticle());
    }
  }

  if (pictureAnswer.length) {
    return <>
      {
        pictureAnswer
          .map(answer => {
            return <div
              key={answer.messageId}
            >
              <div className="bot-chat__toolbar bot-chat__title">
                {answer.text}
              </div>
              <div className="bot-chat__picture-wrapper">
                {answer.picture?.fileUrl
                  ? <img src={answer.picture.fileUrl} role="presentation" alt="" className="bot-chat__picture"/>
                  : <Loader className="bot-chat__picture-loader" nested/>}
              </div>
            </div>;
          })
      }
    </>;
  }

  return <>
    {loading.includes(getArticle) && <Loader nested/>}
    {
      !loading.includes(getArticle) && article
        ? <Article card={article} onClose={article.id !== userProfile?.greetingContentId ? handleCloseArticle : undefined}/>
        : <div className="bot__toolbar">
        </div>
    }
  </>;
};
