import React, {ChangeEvent} from 'react';
import {useIntl} from 'react-intl';
import cn from 'classnames';

import {IArticle} from 'redux/content/interfaces';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import GritxButton from 'components/gritx-button';
import Loader from 'components/loader';

import './styles.scss';

interface IJournalPage {
  journal: IArticle
  currentPage: number
  onChangePage: (btn: 'next' | 'prev') => void
  onClickDone: () => void
  isLastPage: boolean,
  onChangeAnswer: (model: IArticle) => void
}

export const JournalPage = ({
  journal,
  currentPage,
  onClickDone,
  onChangePage,
  isLastPage,
  onChangeAnswer
}: IJournalPage): JSX.Element => {
  const intl = useIntl();

  function handleChangeAnswer(e: ChangeEvent<HTMLTextAreaElement>) {
    if (!journal) {
      return;
    }
    const newJournal = {
      ...journal,
      pages: [...journal.pages]
    };

    newJournal.pages[currentPage].text = e.target.value;
    onChangeAnswer(newJournal);
  }

  function handleChangePage(btn: 'next' | 'prev') {
    return () => onChangePage(btn);
  }

  function handlePressDone() {
    onClickDone();
  }

  if (!journal) {
    return <Loader/>;
  }

  return <>
    <div className="journal__page">
      <div
        className={'journal__question'}
        dangerouslySetInnerHTML={{
          __html: journal.pages[currentPage].prompt || ''
        }}
      />
      <textarea
        className={'journal__answer'}
        placeholder={intl.formatMessage({
          id: 'gritx.content-page.journal.answer.placeholder',
          defaultMessage: 'Respond here...'
        })}
        rows={10}
        maxLength={2000}
        value={journal.pages[currentPage].text || ''}
        onChange={handleChangeAnswer}
      />
    </div>
    <div className={cn('journal__page-controls', {
      'journal__page-controls--first-page': currentPage === 0
    })}>
      <GritxButton
        className={cn('journal__left-button', {'hide-button': currentPage === 0})}
        title={intl.formatMessage({
          id: 'gritx.content-page.journal.previousPage',
          defaultMessage: 'Previous'
        })}
        variant={ButtonVariant.Outline}
        onClick={handleChangePage('prev')}
      />
      {
        isLastPage
          ? <>
            <GritxButton
              className="journal__right-button"
              title={intl.formatMessage({
                id: 'gritx.content-page.journal.done',
                defaultMessage: 'Done'
              })}
              variant={ButtonVariant.Primary}
              onClick={handlePressDone}
            />
          </>
          : <>
            <GritxButton
              className="journal__right-button"
              title={intl.formatMessage({
                id: 'gritx.content-page.journal.nextPage',
                defaultMessage: 'Next'
              })}
              variant={ButtonVariant.Primary}
              onClick={handleChangePage('next')}
            />
          </>
      }
    </div>
  </>;
};
