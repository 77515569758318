import React from 'react';
import cn from 'classnames';

import './styles.scss';

interface ILoaderProps {
  className?: string
}

export const ChatLoader: React.FunctionComponent<ILoaderProps> = ({className}: ILoaderProps) => {
  return <div
    className={cn('chat-loader', className)}
  >
    <div className="chat-loader__bar" />
    <div className="chat-loader__bar" />
    <div className="chat-loader__bar" />
  </div>;
};
