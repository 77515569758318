import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import {IStore} from 'redux/interface';
import {
  getArticle,
  getBackpackContent,
  getLibContent,
  getLibFilters,
  getMaterialsContent,
  getTemplates,
  saveJournal,
  sendMaterials
} from 'redux/pageAsyncActions';
import {clearErrorAction, finishLoadingAction, startLoadingAction} from 'redux/status/actions';
import {IArticle, IGetLibData, ISendMaterials} from './interfaces';
import {errorHandler} from 'redux/errorHandler';
import {
  articleLoadedAction,
  backpackLoadedAction,
  checkFavoriteAction,
  checkMaterialAction,
  contentLoadedAction,
  libFiltersLoadedAction,
  materialsLoadedAction,
  setSendMaterialsStatus,
  setShareTemplates,
  uncheckFavoriteAction,
  uncheckMaterialAction
} from './actions';
import {
  addFavoriteApi,
  addMaterialApi,
  filterContentApi,
  getArticleApi, getFavoritesApi,
  getLibFiltersApi,
  getMaterialsApi, getTemplatesApi,
  removeFavoriteApi,
  removeMaterialApi, saveJournalApi, shareMaterialsApi
} from 'redux/api/content';
import {SystemLanguage} from 'utils/constants/constants';

import {ToastKey} from 'components/gritx-toast/ToastKeyEnum';

export const loadLibContentAction = ({
  filters,
  offset,
  pageSize
}: IGetLibData): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client},
    translation: {locale}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getLibContent;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await filterContentApi(
        {
          filter: {
            type: 'SIMPLE', // todo add to constants
            filters: filters
              .filter(filter => filter.inputValue.length !== 0)
              .map((filter) => {
                return {
                  ...filter,
                  inputValue: typeof filter.inputValue === 'string' ? filter.inputValue : filter.inputValue.join(',')
                };
              })
          },
          lang: locale,
          offset,
          limit: pageSize
        }
      );

      dispatch(clearErrorAction(actionKey));
      dispatch(finishLoadingAction(actionKey));
      dispatch(contentLoadedAction(data));
    } catch (e) {
      console.log('loadContentAction', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadContentAction,
        actionKey,
        error: e,
        currentAction: loadLibContentAction({
          filters,
          offset,
          pageSize
        })
      }));
    }
  }
});
export const loadLibFiltersAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {
      auth0Client,
      userProfile
    }
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getLibFilters;
  const filterType = 'SIMPLE';

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await getLibFiltersApi(filterType, userProfile?.language || SystemLanguage.English);

      dispatch(clearErrorAction(actionKey));
      dispatch(finishLoadingAction(actionKey));
      dispatch(libFiltersLoadedAction(data));
    } catch (e) {
      console.log('getLibFilters', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.GetLibFilters,
        actionKey,
        error: e,
        currentAction: loadLibFiltersAction()
      }));
    }
  }
});
export const loadArticleAction = (id: string): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {
      auth0Client,
      userProfile
    }
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getArticle;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await getArticleApi(id, userProfile?.language || SystemLanguage.English);

      dispatch(finishLoadingAction(actionKey));
      dispatch(clearErrorAction(actionKey));

      dispatch(articleLoadedAction(data));
    } catch (e) {
      console.log('loadArticleAction', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadArticleAction,
        actionKey,
        error: e,
        currentAction: loadArticleAction(id)
      }));
    }
  }
});
export const loadMaterialsContentAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getMaterialsContent;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    try {
      const {data} = await getMaterialsApi(locale);

      dispatch(clearErrorAction(actionKey));
      dispatch(finishLoadingAction(actionKey));
      dispatch(materialsLoadedAction(data));
    } catch (e) {
      console.log('loadMaterialsContentAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadMaterialsContentAction,
        actionKey,
        error: e,
        currentAction: loadMaterialsContentAction()
      }));
    }
  }
});
export const addFavoriteCardAction = (cardId: number): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();

  if (isAuthenticated) {
    try {
      await addFavoriteApi(cardId);
      dispatch(checkFavoriteAction(cardId));
    } catch (e) {
      console.log('addFavoriteCardAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.AddFavorite,
        actionKey: 'addFavorite',
        error: e,
        currentAction: addFavoriteCardAction(cardId)
      }));
    }
  }
});
export const removeFavoriteCardAction = (cardId: number): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();

  if (isAuthenticated) {
    try {
      await removeFavoriteApi(cardId);

      dispatch(uncheckFavoriteAction(cardId));
    } catch (e) {
      console.log('removeFavoriteCardAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.RemoveFavorite,
        actionKey: 'removeFavorite',
        error: e,
        currentAction: removeFavoriteCardAction(cardId)
      }));
    }
  }
});
export const addMaterialAction = (contentId: number): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();

  if (isAuthenticated) {
    try {
      await addMaterialApi({
        contentId: contentId,
        language: locale,
        comments: ''
      });
      dispatch(checkMaterialAction(contentId));
    } catch (e) {
      console.log('addToMaterialAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.AddMaterial,
        actionKey: 'addMaterial',
        error: e,
        currentAction: addMaterialAction(contentId)
      }));
    }
  }
});
export const removeMaterialAction = (contentIds: number[], isAll?: boolean): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();

  if (isAuthenticated) {
    try {
      await removeMaterialApi(contentIds);

      dispatch(uncheckMaterialAction(contentIds));
      if (isAll) {
        dispatch(loadMaterialsContentAction());
      }
    } catch (e) {
      console.log('removeMaterialAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.RemoveMaterial,
        actionKey: 'removeMaterial',
        error: e,
        currentAction: removeMaterialAction(contentIds)
      }));
    }
  }
});
export const loadBackpackContentAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getBackpackContent;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    try {
      const {data} = await getFavoritesApi(locale);

      dispatch(clearErrorAction(actionKey));
      dispatch(finishLoadingAction(actionKey));
      dispatch(backpackLoadedAction(data));
    } catch (e) {
      console.log('loadBackpackContentAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadBackpackContentAction,
        actionKey,
        error: e,
        currentAction: loadBackpackContentAction()
      }));
    }
  }
});
export const loadMaterialsTemplatesAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getTemplates;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getTemplatesApi(locale);

      dispatch(finishLoadingAction(actionKey));
      dispatch(setShareTemplates(data));
    } catch (e) {
      console.log('loadMaterialsTemplates', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadMaterialsTemplates,
        actionKey,
        error: e
      }));
    }
  }
});

export const sendMaterialsAction = (materials: ISendMaterials): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = sendMaterials;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await shareMaterialsApi({
        materials,
        contentLang: locale
      });
      dispatch(finishLoadingAction(actionKey));
      dispatch(setSendMaterialsStatus(true));
    } catch (e) {
      console.log('sendMaterialsAction', e);
      dispatch(finishLoadingAction(actionKey));
      dispatch(setSendMaterialsStatus(true));
      dispatch(errorHandler({
        toastKey: ToastKey.SendMaterialsAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const saveJournalAction = (journal: IArticle): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = saveJournal;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await saveJournalApi(journal);
      dispatch(loadArticleAction(journal.id.toString()));
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('saveJournalAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.SaveJournalAction,
        actionKey,
        error: e
      }));
    }
  }
});
