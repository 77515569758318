export enum Routes {
  Home = '/',
  Logout = '/logout',
  Library = '/library',
  Materials = '/materials',
  Backpack = '/backpack',
  Chat = '/chat',
  Xpedition = '/xpedition',
  Settings = '/settings',
  Content = '/content',
  Callback = '/callback',
}

export enum TranslationKeys {
  Library = 'library',
  Materials = 'materials',
  Backpack = 'backpack',
  Chat = 'chat',
  Settings = 'user-settings',
  Content = 'content-page',
  Error = 'error',
  Footer = 'footer',
  Common = 'common',
  Home = 'home',
  Toasts = 'toasts'
}

export const firstAuthorizedRoute = Routes.Chat;

export const routeTranslationKeys: { [key: string]: string } = {
  [Routes.Library]: TranslationKeys.Library,
  [Routes.Materials]: TranslationKeys.Materials,
  [Routes.Backpack]: TranslationKeys.Backpack,
  [Routes.Chat]: TranslationKeys.Chat,
  [Routes.Xpedition]: TranslationKeys.Chat,
  [Routes.Settings]: TranslationKeys.Settings,
  [Routes.Content]: TranslationKeys.Content
};
