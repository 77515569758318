import React, {FunctionComponent} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {IXpedition} from 'utils/hooks/use-chat-bot/interfaces';

import GritxButton from 'components/gritx-button';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';

import './styles.scss';

interface IXpeditionItem {
  xpedition: IXpedition;
  onStart: (botId: number) => void;
  onRemove: (dialogId: number) => void;
}

export const XpeditionItem: FunctionComponent<IXpeditionItem> = ({
  xpedition,
  onStart,
  onRemove
}: IXpeditionItem) => {
  const intl = useIntl();

  function handleStartXpedition(botId: number) {
    return () => {
      onStart(botId);
    };
  }

  function handleRemoveDialog(item: IXpedition) {
    return () => {
      if (item.dialogId) {
        onRemove(item.dialogId);
      }
    };
  }

  // ----------

  return <div className="xpedition-item">
    <div className="xpedition-item__header">
      <h2 className="xpedition-item__title">
        {xpedition.theme}
      </h2>
    </div>
    <div className="xpedition-item__body">
      <div className="xpedition-item__image-wrapper">
        <img
          className="xpedition-item__image"
          src={xpedition.imageUrl}
          alt={xpedition.theme}
        />
      </div>
      <div className="xpedition-item__description">
        <p>{xpedition.description}</p>
      </div>
      <div className="xpedition-item__button-wrapper">
        <button
          className="xpedition-item__button"
          onClick={handleStartXpedition(xpedition.id)}
          style={{
            background: `linear-gradient(#f2f2f2 ${100 - xpedition.percentage}%, #fc767d ${100 - xpedition.percentage}%)`
          }}
        >
          <span className="xpedition-item__button-percent">
            {`${xpedition.percentage}%`}
          </span>
          <span className="xpedition-item__button-label">
            {
              xpedition.dialogId
                ? <FormattedMessage id={'gritx.common.button.continue'} defaultMessage={'Continue'}/>
                : <FormattedMessage id={'gritx.common.button.start'} defaultMessage={'Start'}/>
            }
          </span>
        </button>
        {
          xpedition.dialogId && <>
            <GritxButton
              className="xpedition-item__button--remove"
              onClick={handleRemoveDialog(xpedition)}
              title={intl.formatMessage({
                id: 'gritx.common.button.start-over',
                defaultMessage: 'Start over'
              })}
              variant={ButtonVariant.Secondary}
            />
          </>
        }
      </div>
    </div>
  </div>;
};
