import React from 'react';
import {FormattedMessage} from 'react-intl';

import Apple from 'assets/image/social-mobile/apple.svg';
import QRApple from 'assets/image/social-mobile/qr-apple.svg';
import AppStore from 'assets/image/social-mobile/AppStore.svg';
import Google from 'assets/image/social-mobile/android.svg';
import QRGoogle from 'assets/image/social-mobile/qr-google.svg';
import GoogleApp from 'assets/image/connect-line/downloadViaGooglePlay.svg';

import {appStoreUrl, googlePlayUrl, intIosAccess} from 'utils/constants/constants';

import './styles.scss';

export const MobileView = (): JSX.Element => {
  return <div className="mobile-view">
    <div className="apps">
      <div className="apps-content">
        <div className="apps__store">
          <div className="apps__qr">
            <div className="apps__qr-logo">
              <img
                className={'apps__qr-image'}
                src={Apple}
                alt="Apple"
              />
            </div>
            <div className="apps__qr-code">
              <img
                className={'apps__qr-code-image'}
                src={QRApple}
                alt="QR Code"
              />
            </div>
          </div>
          <div className="apps__button">
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href={appStoreUrl}
            >
              <img
                src={AppStore}
                alt="App Store"
                className="apps__button-image"
              />
            </a>
          </div>
        </div>

        {/* <div className="apps__button--link">*/}
        {/*  <a*/}
        {/*    target={'_blank'}*/}
        {/*    rel={'noreferrer'}*/}
        {/*    href={intIosAccess}*/}
        {/*    className={'apps__link'}*/}
        {/*  >*/}
        {/*    <FormattedMessage*/}
        {/*      id={'gritx.home.apps.int-access'}*/}
        {/*      defaultMessage={'International iOS Access'}*/}
        {/*    />*/}
        {/*  </a>*/}
        {/* </div>*/}

        {/*<div className="apps__store">*/}
        {/*  <div className="apps__qr">*/}
        {/*    <div className="apps__qr-logo">*/}
        {/*      <img*/}
        {/*        className={'apps__qr-image'}*/}
        {/*        src={Google}*/}
        {/*        alt="Google"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="apps__qr-code">*/}
        {/*      <img*/}
        {/*        className={'apps__qr-code-image'}*/}
        {/*        src={QRGoogle}*/}
        {/*        alt="QR Code"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="apps__button">*/}
        {/*    <a*/}
        {/*      target={'_blank'}*/}
        {/*      rel={'noreferrer'}*/}
        {/*      href={googlePlayUrl}*/}
        {/*    >*/}
        {/*      <img*/}
        {/*        src={GoogleApp}*/}
        {/*        alt="Google Store"*/}
        {/*        className="apps__button-image"*/}
        {/*      />*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  </div>;
};
