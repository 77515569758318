import React, {FunctionComponent, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {IBotAction} from 'utils/hooks/use-chat-bot/interfaces';
import {contentAsyncActions, getArticle} from 'redux/pageAsyncActions';

import DForm from 'components/d-form';

import {IOverlayAction} from './interfaces';

import './styles.scss';

interface IOverlay {
  overlay: IBotAction,
  onChange: (actionBody: string) => void,
  onFinish?: () => void,
  lastMessageDate?: Date
}

enum CheckboxValue {
  True = 'Yes',
  False = 'No'
}

export const Overlay: FunctionComponent<IOverlay> = ({
  overlay,
  onChange,
  onFinish,
  lastMessageDate
}: IOverlay) => {
  const dispatch = useDispatch();
  const [action, setAction] = useState<IOverlayAction>();
  const [dataForSend, setDataForSend] = useState('');
  const [error, setError] = useState('');
  const [isShowError, setIsShowError] = useState(false);

  useEffect(() => {
    const parsedAction = JSON.parse(overlay.actionBody) as IOverlayAction;
    const {
      contentInfo,
      remind,
      form
    } = parsedAction.addActionBody;

    if (contentInfo) {
      const {code} = contentInfo;

      dispatch(contentAsyncActions[getArticle](code));
      setDataForSend(overlay.actionBody);
    }
    if (remind) {
      // todo remove
      setDataForSend(overlay.actionBody);
    }

    if (form && form.finish && onFinish) {
      onFinish();
    }
    setAction(parsedAction);
  }, []);

  useEffect(() => {
    onChange(dataForSend);
  }, [dataForSend]);

  function handleChangeDCheckbox(controlIdx: number) {
    return (inputIdx: number, checkboxData: boolean) => {
      const newAction = action;

      if (!newAction?.addActionBody.form) {
        return;
      }
      const currentControl = newAction
        .addActionBody
        .form.controls[controlIdx];
      const selectedPrev = currentControl.inputs.filter(input => input.value !== CheckboxValue.False && input.type === 'checkbox');

      if (checkboxData && selectedPrev.length === currentControl.max) {
        return;
      }

      newAction
        .addActionBody
        .form
        .controls[controlIdx]
        .inputs[inputIdx]
        .value = checkboxData ? CheckboxValue.True : CheckboxValue.False;

      const changedValue = JSON.stringify(newAction);

      setAction(JSON.parse(changedValue));

      const selectedCur = currentControl.inputs.filter(input => input.value !== 'No' && input.type === 'checkbox');

      if (currentControl.min && selectedCur.length < currentControl.min) {
        setError(`please check ${currentControl.min} items`); // todo
        setDataForSend('');
      } else {
        setError('');
        setIsShowError(false);
        setDataForSend(changedValue);
      }
    };
  }

  function handleChangeDRadio(controlIdx: number) {
    return (inputIdx: number, radioValue: string) => {
      const newAction = action;

      if (!newAction?.addActionBody.form) {
        return;
      }
      newAction
        .addActionBody
        .form
        .controls[controlIdx]
        .inputs[inputIdx]
        .value = radioValue;

      const changedValue = JSON.stringify(newAction);

      setAction(JSON.parse(changedValue));
      setDataForSend(changedValue);
    };
  }

  function handleChangeDInput(controlIdx: number) {
    return (inputIdx: number, inputData: string) => {
      const newAction = action;

      if (!newAction?.addActionBody.form) {
        return;
      }
      newAction
        .addActionBody
        .form
        .controls[controlIdx]
        .inputs[inputIdx]
        .value = inputData;

      const changedValue = JSON.stringify(newAction);

      setAction(JSON.parse(changedValue));
      if (inputData.length) {
        setDataForSend(changedValue);
      } else {
        setDataForSend('');
      }
    };
  }

  function handleChangeDButton(controlIdx: number) {
    return (inputIdx: number, inputData: string) => {
      const newAction = action;

      if (!newAction?.addActionBody.form) {
        return;
      }
      newAction
        .addActionBody
        .form
        .controls[controlIdx]
        .inputs[inputIdx]
        .value = inputData;

      const changedValue = JSON.stringify(newAction);

      setAction(JSON.parse(changedValue));
      if (inputData.length) {
        setDataForSend(changedValue);
      } else {
        setDataForSend('');
      }
    };
  }

  function handleChangeDChoice(controlIdx: number) {
    return (inputIdx: number, choiceValue: string) => {
      const newAction = action;

      if (!newAction?.addActionBody.form) {
        return;
      }
      newAction
        .addActionBody
        .form
        .controls[controlIdx]
        .inputs[inputIdx]
        .value = choiceValue;

      const changedValue = JSON.stringify(newAction);

      setAction(JSON.parse(changedValue));
      setDataForSend(changedValue);
    };
  }

  if (!action) {
    return null;
  }
  const {
    form,
    contentInfo,
    actions,
    remind
  } = action.addActionBody;

  if (form) {
    return <div>
      {
        form.controls.map((control, controlIdx) => {
          return <div className="overlay" key={controlIdx}>
            <DForm
              control={control}
              onChangeCheckbox={handleChangeDCheckbox(controlIdx)}
              onChangeRadiobutton={handleChangeDRadio(controlIdx)}
              onChangeInput={handleChangeDInput(controlIdx)}
              onChangeChoice={handleChangeDChoice(controlIdx)}
              onChangeButton={handleChangeDButton(controlIdx)}
            />
          </div>;
        })
      }
      {isShowError && <p>{error}</p>}
    </div>;
  }
  if (contentInfo) {
    return null;
  }
  if (lastMessageDate && remind && actions.filter((item) => item.value_code === 'Overlay').length) {
    return <button
      // onClick={handleSendOverlayForm(overlayIdx)} todo fix
    >
      timer {remind - (new Date().getTime() - lastMessageDate.getTime()) / 1000 / 60}
    </button>;
  }

  return <p>{JSON.stringify(action.addActionBody)}</p>;
};
