import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';

import {ReactComponent as FacebookIcon} from 'assets/image/connect-line/facebook.svg';
import {ReactComponent as InstagramIcon} from 'assets/image/connect-line/instagram.svg';
import {ReactComponent as TwitterIcon} from 'assets/image/connect-line/twitter.svg';
import {ReactComponent as DownloadViaAppStoreIcon} from 'assets/image/connect-line/downloadViaAppStore.svg';
import {ReactComponent as DownloadViaGooglePlayIcon} from 'assets/image/connect-line/downloadViaGooglePlay.svg';

import {twitterUrl, facebookUrl, instagramUrl, googlePlayUrl, appStoreUrl} from 'utils/constants/constants';

import './styles.scss';

export const ConnectLine: FunctionComponent = () => {
  return <div className="connect-line-color">
    <div className="container container__wrapper">
      <div className="connect-line">
        <section className="connect-line__info connect-line__info__connect">
          <FormattedMessage
            id={'gritx.footer.connect.connect'}
            defaultMessage={'connect'}
          />
          <a href={twitterUrl}
            target="_blank"
            rel="noreferrer"
            className="connect-line__info__connect-button connect-line__info__bird-picture">
            <TwitterIcon className="connect-line__info__twitter"/>
            <span className="visually-hidden">
              <FormattedMessage
                id={'gritx.footer.socialLink.twitter'}
                defaultMessage={'Visit us on Twitter'}
              />
            </span>
          </a>
          <a href={facebookUrl}
            target="_blank"
            rel="noreferrer"
            className="connect-line__info__connect-button connect-line__info__square-picture">
            <FacebookIcon/>
            <span className="visually-hidden">
              <FormattedMessage
                id={'gritx.footer.socialLink.facebook'}
                defaultMessage={'Visit us on Facebook'}
              />
            </span>
          </a>
          <a href={instagramUrl}
            target="_blank"
            rel="noreferrer"
            className="connect-line__info__connect-button connect-line__info__square-picture">
            <InstagramIcon/>
            <span className="visually-hidden">
              <FormattedMessage
                id={'gritx.footer.socialLink.instagram'}
                defaultMessage={'Visit us on Instagram'}
              />
            </span>
          </a>
        </section>
        <section className="connect-line__info connect-line__info__get">
          <FormattedMessage
            id={'gritx.footer.connect.getMobile'}
            defaultMessage={'get mobile'}
          />
          <a href={appStoreUrl}
            target="_blank"
            rel="noreferrer"
            className="connect-line__info__download-app">
            <DownloadViaAppStoreIcon className="connect-line__info__download-app"/>
            <span className="visually-hidden">
              <FormattedMessage
                id={'gritx.common.downloadApp.appStore'}
                defaultMessage={'Go to the download application in App Store'}
              />
            </span>
          </a>
          {/*<a href={googlePlayUrl}*/}
          {/*  target="_blank"*/}
          {/*  rel="noreferrer"*/}
          {/*  className="connect-line__info__download-google">*/}
          {/*  <DownloadViaGooglePlayIcon className="connect-line__info__download-google"/>*/}
          {/*  <span className="visually-hidden">*/}
          {/*    <FormattedMessage*/}
          {/*      id={'gritx.common.downloadApp.googlePlay'}*/}
          {/*      defaultMessage={'Go to the download application in Google Play'}*/}
          {/*    />*/}
          {/*  </span>*/}
          {/*</a>*/}
        </section>
      </div>
    </div>
  </div>;
};
