import React, {FunctionComponent} from 'react';

import ChatLoader from './chat-loader';

import './styles.scss';

interface IBotLoader {
  isLoading: boolean
}

export const BotLoader: FunctionComponent<IBotLoader> = ({isLoading}: IBotLoader) => {
  return isLoading ? <div className="bot-chat__message bot-chat__message--left message-loading">
    <div className="bot-chat__message-value">
      <ChatLoader/>
    </div>
  </div> : null;
};
