import {useDispatch, useSelector} from 'react-redux';
import {IStore} from 'redux/interface';
import {loadMediaAction} from 'redux/media/mediaAsyncActions';
import {IMediaInitialState} from 'redux/media/interfaces';

interface IUseLoadFile {
  media: IMediaInitialState
  getFileUrl: (fileId: number) => string
}

export function useLoadFile(): IUseLoadFile {
  const {
    media,
    status: {loading}
  } = useSelector((store: IStore) => store);
  const dispatch = useDispatch();

  function getFileUrl(fileId: number): string {
    if (media[fileId]) {
      return media[fileId];
    }
    if (!loading.includes(fileId.toString())) {
      dispatch(loadMediaAction(fileId));
    }

    return '';
  }

  return {
    getFileUrl,
    media
  };
}
