import React, {FunctionComponent, useEffect, useState} from 'react';

import {BotActionType, IBotAction, IBotAnswer} from 'utils/hooks/use-chat-bot/interfaces';
import Overlay from '../overlay';

interface IXpeditionOverlay {
  botAnswers: IBotAnswer[]
  onChange: (message: IBotAction | null) => void
  onFinish: () => void
}

export const InterviewOverlay: FunctionComponent<IXpeditionOverlay> = ({
  botAnswers,
  onChange,
  onFinish
}: IXpeditionOverlay) => {
  const [overlays, setOverlays] = useState<IBotAction[]>([]);

  useEffect(() => {
    const overlayList = botAnswers.reduce<IBotAction[]>((acc, answer) => {
      if (answer.keyboard?.buttons) {
        answer.keyboard.buttons.forEach((item) => {
          if (item.actionType === BotActionType.Overlay) {
            return acc.push(item);
          }

          return acc;
        });
      }

      return acc;
    }, []);

    setOverlays(overlayList);
  }, [botAnswers]);

  function handleChangeOverlay(overlayIdx: number) {
    return (actionBody: string) => {
      if (actionBody.length === 0) {
        onChange(overlays[overlayIdx]);

        return;
      }
      onChange({
        ...overlays[overlayIdx],
        actionBody
      });
    };
  }

  function handleFinishInterview() {
    onFinish();
  }

  return <>
    {
      overlays.map((overlay, overlayIdx) => {
        return <Overlay
          key={overlayIdx}
          overlay={overlay}
          onChange={handleChangeOverlay(overlayIdx)}
          onFinish={handleFinishInterview}
        />;
      })
    }
  </>;
};
