import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {IStore} from 'redux/interface';
import {finishLoginAction} from 'redux/auth/authAsyncActions';

import Loader from 'components/loader';

import {Routes} from 'utils/constants/routeConstants';

import './styles.scss';

export const Callback = (): JSX.Element => {
  const {
    auth: {
      auth0User,
      userProfile
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const [redirectUri, setRedirectUri] = useState<string>(Routes.Home);

  useEffect(() => {
    setTimeout(() => dispatch(finishLoginAction()), 1000);
    const searchParams = new URLSearchParams(window.location.search);
    const redirectUriValue = searchParams.get('appRedirectUri');

    if (redirectUriValue) {
      setRedirectUri(redirectUriValue);
    }
  }, []);

  if (auth0User && userProfile) {
    return <Redirect to={redirectUri}/>;
  }

  return <div className="callback container">
    <div className="callback__loader">
      <Loader/>
    </div>
  </div>;
};
